import React from "react"
import PageTransition from "./src/components/PageTransition"

export const wrapPageElement = ({ element, props }) => {
  return <PageTransition {...props}>{element}</PageTransition>
}

export const shouldUpdateScroll = () => {
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual"
  }

  return false
}
