import React, { useState, useEffect } from "react"
import { AnimatePresence, motion } from "framer-motion"
import Menu from "./Menu"
import CategoryFilter from "./CategoryFilter"
import Footer from "./Footer"
import "../styles/main.css"
import * as talentStyles from "../styles/components/Talent.module.css"

const PageTransition = ({ children, path, pageContext: { isCategoryPage, isTalentPage, title } }) => {
  const [menuState, setMenuState] = useState()
  const [showFooter, setShowFooter] = useState(false)
  const [talentInitMomentCompleted, setTalentInitMomentCompleted] = useState()
  const [colourString, setColourString] = useState()

  const overlayColours = ["velvetRed", "pistachioGreen", "fadedLavender"]

  useEffect(() => {
    setShowFooter(path !== "/")
  }, [path])

  useEffect(() => {
    // This will set the state of the talent moment completion to false 
    // both when we enter and leave the talent page, which is fine.
    setTalentInitMomentCompleted(false)

    if(isTalentPage) {
      setColourString(overlayColours[Math.floor(Math.random() * overlayColours.length)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTalentPage])

  return (
    <>
      <Menu path={path} menuState={menuState} setMenuState={setMenuState} isCategoryPage={isCategoryPage} isTalentPage={isTalentPage} />

      <AnimatePresence>{isCategoryPage && <CategoryFilter path={path} menuState={menuState} isTalentPage={isTalentPage} />}</AnimatePresence>

      <AnimatePresence>
        {isTalentPage && !talentInitMomentCompleted && (
          <motion.div
            key="overlay"
            className={`${talentStyles.overlay} ${talentStyles[colourString]}`}
            initial={{ y: "100%" }}
            animate={{
              y: 0,
              transition: {
                type: "tween",
                duration: 1,
              },
            }}
            exit={{
              y: "100%",
              transition: {
                type: "tween",
                duration: 1,
                delay: 1.5,
              },
            }}
            onClick={() => setTalentInitMomentCompleted(true)}
          >
            <motion.div
              className={talentStyles.overlayInner}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 1,
                },
              }}
              onAnimationComplete={(def) => {
                if (def.opacity === 1) {
                  setTalentInitMomentCompleted(true)
                }
              }}
              exit={{ opacity: 0, transition: { delay: 1 } }}
            >
              {title}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait">{children}</AnimatePresence>
      {showFooter && <Footer />}
    </>
  )
}

export default PageTransition
