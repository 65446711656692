import React, { useState } from "react"
import { Link } from "gatsby"
import { useSiteOptions } from "../../hooks/use-site-options"
import { AnimatePresence, motion } from "framer-motion"
import {
  mainMenu,
  inner,
  backBar,
  backLine,
  menuToggle,
  logo,
  rule,
  mobileTalentLink,
  mobileNewsLink,
  desktopTalentLink,
  desktopNewsLink,
  bottomLinks,
  aboutLink,
  contactLink,
  signUpLink,
  instaLink,
  aboutContent,
  aboutSubcontent,
  homeLogoWrap,
  homeLogo,
} from "../../styles/components/Menu.module.css"
import Section from "./Section"
import NewsletterForm from "../NewsletterForm"
import AlmaLogo from "../icons/AlmaLogo"
import { useBreakpoint } from "../../hooks/use-breakpoint"
import { useEffect } from "react"
import MenuText from "../icons/MenuText"

const MotionLink = motion(Link)
const MotionLogo = motion(AlmaLogo)

const Menu = ({ path, menuState, setMenuState, isCategoryPage, isTalentPage }) => {
  const [section, setSection] = useState(0)
  const [showHomeLogo, setShowHomeLogo] = useState(false)
  const [menuToggleState, setMenuToggleState] = useState("hidden")
  const [isHome, setIsHome] = useState()
  const [logoState, setLogoState] = useState()
  const [deskTalentLinkState, setDeskTalentLinkState] = useState()
  const [deskNewsLinkState, setDeskNewsLinkState] = useState()
  const [backBarState, setBackBarState] = useState("hidden")
  const [homeLogoState, setHomeLogoState] = useState("unswiped")
  const [openDuration, setOpenDuration] = useState(0.75)

  const siteOptions = useSiteOptions()
  const breakpoint = useBreakpoint()

  useEffect(() => {
    setIsHome(path === "/")

    if (path === "/") {
      setOpenDuration(1.5)
    } else {
      setOpenDuration(0.75)
    }
  }, [path])

  // Set the backBar state
  useEffect(() => {
    if (!isHome && menuState !== "open") {
      if (isCategoryPage && breakpoint.value === "mobile") {
        setBackBarState("visibleTall")
      } else {
        setBackBarState("visible")
      }
    } else {
      setBackBarState("hidden")
    }
  }, [isHome, breakpoint.value, isCategoryPage, menuState])

  useEffect(() => {
    // If isHome changes to true, then we've just navigated to the
    // home page so we can use it as an initialisation state
    setShowHomeLogo(isHome && menuState !== "open")
    setHomeLogoState("unswiped")
  }, [isHome, menuState])

  // Logic for placement of menu and toggle for homepage
  useEffect(() => {
    if (isHome === true && !menuState) {
      setMenuState("initDown")
      setMenuToggleState("initBottom")
    } else if (isHome === false) {
      setMenuToggleState("initTop")
    } else if (isHome === true && menuState === "open") {
      setMenuToggleState("top")
    }
  }, [menuState, isHome, setMenuState])

  // Logic for them main menu setup
  useEffect(() => {
    /*
     * 1/ Hidden when menu closed
     * 2/ Visible when no section are visible or when not mobile, but not letterbox
     * 3/ Therefore hidden when sections are visible, not mobile when the screen is letterboxed
     */
    if (menuState !== "open") {
      setLogoState("hidden")
    } else if (section === 0 || (breakpoint.value !== "mobile" && !breakpoint.letterbox)) {
      setLogoState("visible")
    } else {
      setLogoState("hidden")
    }
  }, [menuState, section, breakpoint])

  // Desktop top link animation states
  useEffect(() => {
    const newsState = isHome === false || menuState !== "initDown" ? "visible" : "hidden"
    setDeskNewsLinkState(newsState)

    const talentState = (isHome === false || menuState !== "initDown") && !(isCategoryPage && menuState !== "open") ? "visible" : "hidden"
    setDeskTalentLinkState(talentState)
  }, [menuState, isHome, isCategoryPage])

  // Mobile section display
  const handleSectionButtonClick = (sectionNumber) => {
    if (breakpoint.value === "mobile") {
      if (section === sectionNumber) {
        setSection(0)
      } else {
        setSection(sectionNumber)
      }
    }
  }

  // Desktop section display
  const handleSectionRollSelect = (sectionNumber) => {
    if (breakpoint.value !== "mobile") {
      setSection(sectionNumber)
    }
  }

  // const closeMenu = () => {
  //   setMenuState("closed")
  //   setSection(0)
  // }

  const closeMenuDown = () => {
    // We can also trigger closeMenuDown from the head menu items
    // with the menu closed, in which case changing the menu state
    // will result in an ugly animation
    if (menuState === "open") {
      setMenuState("closedDown")
      setSection(0)
    }
  }

  const openMenu = () => {
    setMenuState("open")
    setHomeLogoState("swiped")
  }

  const handlePageNavigateClick = () => {
    // We want to set home to false asap so we don't get the logo flashing back in while the page loads
    setIsHome(false)
    closeMenuDown()
  }

  const menuToggleVariants = {
    hidden: {
      top: 0,
      bottom: "auto",
      y: "-100%",
    },
    top: {
      top: 0,
      y: 0,
      x: breakpoint.value === "mobile" ? 0 : "-50%",
      transition: {
        type: "tween",
        duration: openDuration,
        delay: 0.005,
      },
    },
    initTop: {
      top: 0,
      bottom: "auto",
      y: 0,
      x: breakpoint.value === "mobile" ? 0 : "-50%",
      transition: {
        duration: 0,
      },
    },
    initBottom: {
      bottom: 0,
      top: "auto",
      y: 0,
      x: breakpoint.value === "mobile" ? 0 : "-50%",
      transition: {
        duration: 0,
      },
    },
  }

  const backBarVariants = {
    hidden: {
      opacity: 0,
      y: 0,
      transition: {
        when: "beforeChildren",
        type: "tween",
        y: {
          delay: 0.3,
        },
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: openDuration - 0.25,
        when: "beforeChildren",
        type: "tween",
        y: {
          delay: isTalentPage ? 1 + openDuration - 0.25 : openDuration - 0.25,
        },
      },
    },
    visibleTall: {
      opacity: 1,
      y: "34px",
      transition: {
        delay: openDuration - 0.25,
        when: "beforeChildren",
        type: "tween",
      },
    },
  }

  const backLineVariants = {
    hidden: {
      scaleX: 0,
    },
    visible: {
      scaleX: 1,
      transition: {
        duration: 0.5,
      },
    },
    visibleTall: {
      scaleX: 1,
      transition: {
        duration: 0.5,
      },
    },
  }

  const menuVariants = {
    open: {
      y: 0,
      transition: {
        duration: openDuration,
        type: "tween",
        when: "beforeChildren",
        staggerChildren: breakpoint.value === "mobile" ? 0.05 : 0,
      },
    },
    closed: {
      y: "-100%",
      transition: {
        duration: openDuration,
        type: "tween",
        when: "afterChildren",
      },
    },
    closedDown: {
      y: "100%",
      transition: {
        duration: openDuration,
        type: "tween",
        when: "afterChildren",
      },
      transitionEnd: {
        y: "-100%",
      },
    },
    // For some reason I couldn't set the initial value dependent on isHome (isHome
    // always started undefined, even if the path was defined), so we need an animation
    // to jump the menu to the bottom
    initDown: {
      y: "100%",
      transition: {
        duration: 0,
      },
    },
  }

  const logoVariants = {
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        type: "tween",
        delay: openDuration,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.1,
        type: "tween",
      },
    },
  }

  const menuItemVariants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
    closedDown: { opacity: 0 },
    initDown: breakpoint.value === "mobile" ? { opacity: 0 } : { opacity: 1 },
  }

  const menuItemTransition = {
    type: "tween",
  }

  const desktopLinkVariants = {
    visible: { y: 0 },
    hidden: { y: "-100vh" },
  }

  const desktopLinkTransition = { type: "tween", duration: openDuration, delay: isTalentPage ? 1 : 0 }

  const hrVariants = {
    visible: {
      scaleX: 1,
    },
    hidden: {
      opacity: 0,
      scaleX: 0,
      transition: { scaleX: { delay: 0.5 } },
    },
  }

  const homeLogoVariants = {
    unswiped: {
      backgroundPosition: "0%",
      transition: {
        type: "tween",
        duration: 0.2,
      },
    },
    swiped: {
      backgroundPosition: "0% 100%",
      transition: {
        type: "tween",
        duration: openDuration,
      },
    },
  }

  return (
    <>
      <motion.div className={backBar} initial={{ opacity: 0 }} animate={backBarState} variants={backBarVariants}>
        <motion.div className={backLine} initial={{ scaleX: 0 }} variants={backLineVariants} />
      </motion.div>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: isTalentPage ? 1 : 0 } }}>
        <motion.button
          variants={menuToggleVariants}
          initial="hidden"
          animate={menuToggleState}
          className={menuToggle}
          onClick={() => openMenu()}
          onAnimationComplete={(def) => {
            if (def === "top") {
              // Setting bottom: "auto" in the "top" property of menuToggleVariants was causing a glitchy animation
              // but we need it set so that scrolling works nicely, so set to initTop after the animation
              setMenuToggleState("initTop")
            }
          }}
        >
          <MenuText />
        </motion.button>

        <MotionLink
          initial="hidden"
          animate={deskTalentLinkState}
          variants={desktopLinkVariants}
          transition={desktopLinkTransition}
          to="/talent/"
          className={desktopTalentLink}
          onClick={() => handlePageNavigateClick()}
        >
          Talent
        </MotionLink>
        <MotionLink
          initial="hidden"
          animate={deskNewsLinkState}
          variants={desktopLinkVariants}
          transition={desktopLinkTransition}
          to="/news/"
          className={desktopNewsLink}
          onClick={() => handlePageNavigateClick()}
        >
          News
        </MotionLink>
      </motion.div>

      <motion.nav className={mainMenu} initial="closed" animate={menuState} variants={menuVariants}>
        <div className={inner}>
          <MotionLink variants={menuItemVariants} transition={menuItemTransition} to="/talent/" className={mobileTalentLink} onClick={() => handlePageNavigateClick()}>
            Talent
          </MotionLink>
          <MotionLink variants={menuItemVariants} transition={menuItemTransition} to="/news/" className={mobileNewsLink} onClick={() => handlePageNavigateClick()}>
            News
          </MotionLink>

          <MotionLogo className={logo} variants={logoVariants} transition={{ type: "tween", delay: openDuration }} animate={logoState} />

          <motion.hr layout className={rule} variants={hrVariants} initial={{ scaleX: 0 }} animate={section === 0 ? "hidden" : "visible"} transition={{ duration: 0.5 }} />

          <div className={bottomLinks}>
            <motion.button
              variants={menuItemVariants}
              transition={menuItemTransition}
              className={aboutLink}
              onClick={() => handleSectionButtonClick(1)}
              onMouseEnter={() => handleSectionRollSelect(1)}
            >
              About
            </motion.button>
            <Section visible={section === 1} sectionKey={1} handlePageNavigateClick={handlePageNavigateClick}>
              <div className={aboutContent} dangerouslySetInnerHTML={{ __html: siteOptions.aboutContent }}></div>
              <div className={aboutSubcontent} dangerouslySetInnerHTML={{ __html: siteOptions.aboutSubcontent }}></div>
            </Section>
            <motion.button
              variants={menuItemVariants}
              transition={menuItemTransition}
              className={contactLink}
              onClick={() => handleSectionButtonClick(2)}
              onMouseEnter={() => handleSectionRollSelect(2)}
            >
              <span>Contact</span>
            </motion.button>
            <Section visible={section === 2} sectionKey={2} handlePageNavigateClick={handlePageNavigateClick}>
              <div dangerouslySetInnerHTML={{ __html: siteOptions.contactContent }}></div>
            </Section>
            <motion.button
              variants={menuItemVariants}
              transition={menuItemTransition}
              className={signUpLink}
              onClick={() => handleSectionButtonClick(3)}
              onMouseEnter={() => handleSectionRollSelect(3)}
            >
              <span>Sign up</span>
            </motion.button>
            <Section visible={section === 3} sectionKey={3} handlePageNavigateClick={handlePageNavigateClick}>
              <NewsletterForm>
                <div dangerouslySetInnerHTML={{ __html: siteOptions.newsletterContent }}></div>
              </NewsletterForm>
            </Section>
            <motion.a variants={menuItemVariants} transition={menuItemTransition} className={instaLink} href={siteOptions.instagramUrl} target="_blank" rel="noreferrer">
              Insta
            </motion.a>
          </div>
        </div>
      </motion.nav>

      <AnimatePresence>
        {showHomeLogo && (
          <motion.div
            key="homeLogo"
            className={homeLogoWrap}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }} // Delay here stops the logo flashing in when we move from the homepage with the menu open (there's a moment when the menu is closing that showHomeLogo is true)
            exit={{ opacity: 0, transition: { delay: isHome ? openDuration + 0.2 : 0 } }}
            onAnimationComplete={(def) => {
              // When we initially show the home logo we want the menu to open from the bottom
              if (def.opacity === 1) {
                setMenuState("initDown")
              }
            }}
          >
            <motion.h1
              className={homeLogo}
              variants={homeLogoVariants}
              animate={homeLogoState}
              onAnimationComplete={(def) => {
                // Once the home menu has swiped, we can safely hide it
                if (def === "swiped") {
                  setShowHomeLogo(false)
                  setOpenDuration(0.75)
                }
              }}
            >
              Alma
            </motion.h1>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Menu
