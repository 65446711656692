import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { motion } from "framer-motion"
import * as Yup from "yup"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { introContent, fieldset, input, button, successMessage, errorMessage } from "../styles/components/NewsletterForm.module.css"

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
})

const NewsletterForm = ({ children }) => {
  const [buttonText, setButtonText] = useState("Send")

  return (
    <div>
      <div className={introContent}>{children}</div>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          addToMailchimp(values.email)
            .then((data) => {
              if (data.result === "success") {
                setButtonText("Sent")
                actions.resetForm({
                  status: {
                    success: true,
                    // message: data.msg,
                  },
                })
              } else {
                actions.setStatus({
                  success: false,
                  message: data.msg,
                })
              }
            })
            .catch(() => {
              actions.setStatus({
                success: false,
                message: `There was a problem sumbitting to MailChimp. Please try again.`,
              })
            })
            .finally(() => {
              actions.setSubmitting(false)
            })
        }}
      >
        {({ isSubmitting, status, values, isValid, setStatus, handleChange }) => (
          <Form>
            <fieldset className={fieldset}>
              <Field
                className={input}
                name="email"
                placeholder="email address"
                onKeyUp={() => {
                  setButtonText("Send")
                  setStatus("")
                }}
              />
              {((values.email && isValid) || status?.success === true) && (
                <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={button} type="submit" disabled={isSubmitting}>
                  {buttonText}
                </motion.button>
              )}
            </fieldset>
            <ErrorMessage name="email" component="div" className={errorMessage} />
            {status && <div className={`${status.success ? successMessage : errorMessage}`} dangerouslySetInnerHTML={{ __html: status.message }}></div>}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NewsletterForm
