import React, { forwardRef } from "react"
import { inner, svg, altFill } from "../../styles/components/AlmaLogo.module.css"

const AlmaLogo = forwardRef(({ altColour, className }, ref) => {
  return (
    <div ref={ref} className={className}>
      <div className={inner}>
        <svg className={`${svg} ${altColour ? altFill : ""}`} xmlns="http://www.w3.org/2000/svg" width="325" height="67" viewBox="0 0 325 67">
          <path d="M284.979,0 L255.77,66.859 L267.719,66.859 L274.548,50.831 L305.939,50.831 L312.672,66.859 L325.002,66.859 L295.791,0 L284.979,0 Z M290.195,13.941 L301.67,40.494 L278.814,40.494 L290.195,13.941 Z M29.21,0 L0.002,66.859 L11.951,66.859 L18.779,50.831 L50.17,50.831 L56.904,66.859 L69.232,66.859 L40.021,0 L29.21,0 Z M34.425,13.941 L45.902,40.495 L23.047,40.495 L34.425,13.941 Z M218.69,0.474 L198.49,31.865 L178.29,0.474 L165.866,0.474 L165.866,66.859 L177.341,66.859 L177.341,19.441 L198.11,50.547 L198.489,50.547 L219.448,19.247 L219.448,66.859 L231.113,66.859 L231.113,0.474 L218.69,0.474 Z M93.89,0.474 L93.89,66.859 L140.455,66.859 L140.455,56.238 L105.555,56.238 L105.555,0.474 L93.89,0.474 Z" />
        </svg>
      </div>
    </div>
  )
})

export default AlmaLogo
