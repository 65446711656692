// extracted by mini-css-extract-plugin
export var aboutContent = "Menu-module--aboutContent--0vp60";
export var aboutLink = "Menu-module--aboutLink--7WdeK Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var aboutSubcontent = "Menu-module--aboutSubcontent--HYVB0";
export var backBar = "Menu-module--backBar--0lnUu";
export var backLine = "Menu-module--backLine--ytVbj";
export var bottomLinks = "Menu-module--bottomLinks--xwHsa";
export var contactLink = "Menu-module--contactLink--43TPh Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var desktopNewsLink = "Menu-module--desktopNewsLink--DrP5A Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var desktopTalentLink = "Menu-module--desktopTalentLink--8ZsqV Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var homeLogo = "Menu-module--homeLogo--BocdD";
export var homeLogoWrap = "Menu-module--homeLogoWrap--WMatu";
export var inner = "Menu-module--inner--TzHjh";
export var instaLink = "Menu-module--instaLink--C3M7w Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var logo = "Menu-module--logo--EtIcL";
export var mainMenu = "Menu-module--mainMenu--jJF-G";
export var menuLink = "Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var menuToggle = "Menu-module--menuToggle--670ZC composers-module--unButton--9ZYj7";
export var mobileNewsLink = "Menu-module--mobileNewsLink--qSu2f Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var mobileTalentLink = "Menu-module--mobileTalentLink--ZwwdU Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";
export var rule = "Menu-module--rule--UWsxc";
export var sectionSpacer = "Menu-module--sectionSpacer--Tsqrm";
export var sectionWrap = "Menu-module--sectionWrap--h6Qyr";
export var sectionWrapContent = "Menu-module--sectionWrapContent--KF8Gr";
export var sectionWrapContentInner = "Menu-module--sectionWrapContentInner--8Z4cd";
export var signUpLink = "Menu-module--signUpLink---FQRV Menu-module--menuLink--5xf5T composers-module--unButton--9ZYj7";