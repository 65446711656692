import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { sectionWrap, sectionWrapContent, sectionWrapContentInner } from "../../styles/components/Menu.module.css"
import { useBreakpoint } from "../../hooks/use-breakpoint"

const Section = ({ children, visible, sectionKey, handlePageNavigateClick }) => {
  const breakpoint = useBreakpoint()

  // Close menu if we click an anchor tag that's navigating within the site
  const handleContentClicks = (e) => {
    if (e.target.tagName.toLowerCase() === "a" && typeof window !== "undefined" && e.target.href.startsWith(window.location.origin)) {
      handlePageNavigateClick()
    }
  }

  const mobileVariants = {
    visible: {
      height: "auto",
      transition: {
        duration: 0.3,
        type: "tween",
        when: "beforeChildren",
      },
    },
    hidden: {
      height: 0,
      transition: {
        duration: 0.3,
        type: "tween",
        when: "afterChildren",
      },
    },
  }

  const desktopVariants = {
    visible: {
      width: "100%",
      opacity: 1,
      transition: {
        width: {
          duration: 0,
          delay: 0.15,
        },
        opacity: {
          duration: 0.15,
          delay: 0.15,
        },
      },
    },
    hidden: {
      opacity: 0,
      width: 0,
      transition: {
        opacity: {
          duration: 0.15,
        },
        width: {
          duration: 0,
          delay: 0.15,
        },
      },
    },
  }

  const contentVariants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  }

  return (
    <AnimatePresence>
      {visible && (
        <motion.div key={sectionKey} variants={breakpoint.value === "mobile" ? mobileVariants : desktopVariants} initial="hidden" animate="visible" exit="hidden" className={sectionWrap}>
          <motion.div variants={contentVariants} transition={{ duration: 0.2 }} className={sectionWrapContent}>
            {
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div className={sectionWrapContentInner} onClick={handleContentClicks}>
                {children}
              </div>
            }
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Section
