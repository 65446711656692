import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import { globalHistory } from "@reach/router"
import { getPageType } from "../utils/get-page-type"
import { wrap, filterToggle, itemsWrap, items, item, rule } from "../styles/components/CategoryFilter.module.css"
import { useEffect } from "react"

const MotionLink = motion(Link)

const CategoryFilter = ({ menuState, path }) => {
  const {
    allWpCategory: { nodes: categories },
  } = useStaticQuery(
    graphql`
      query CategoryQuery {
        allWpCategory(filter: { count: { gte: 1 } }) {
          nodes {
            slug
            name
            categoryFields {
              pluralName
            }
          }
        }
      }
    `
  )

  const [visible, setVisible] = useState()
  const [categoryExitDelay, setCategoryExitDelay] = useState(0)

  useEffect(() => {
    globalHistory.listen(({ location }) => {
      if (getPageType(location.pathname) === "talent") {
        setCategoryExitDelay(1)
      } else {
        setCategoryExitDelay(0)
      }
    })
  }, [])

  useEffect(() => {
    if (menuState === "open") {
      setVisible(false)
    }
  }, [menuState])

  useEffect(() => {
    setVisible(false)
  }, [path])

  const handleToggleClick = () => {
    setVisible((prev) => !prev)
  }

  const handleLinkClick = () => {
    setVisible(false)
    
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0)
    }
  }

  const filterItemsVariants = {
    visible: {
      y: 0,
      transition: {
        duration: 0.75,
        when: "beforeChildren",
        staggerChildren: 0.1,
        type: "tween",
      },
    },
    hidden: {
      y: "-100%",
      transition: {
        duration: 0.75,
        when: "afterChildren",
        type: "tween",
        delay: menuState === "open" ? 0.5 : 0,
      },
    },
  }

  const itemVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }

  return (
    <motion.div key="categoryFilterWrap" className={wrap} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { delay: categoryExitDelay } }}>
      <button className={filterToggle} onClick={() => handleToggleClick()}>
        Category
      </button>
      <AnimatePresence>
        {visible && (
          <>
            <motion.div key="filterItems" className={itemsWrap} variants={filterItemsVariants} initial="hidden" animate="visible" exit="hidden">
              <div className={items}>
                <MotionLink to="/talent/" className={item} variants={itemVariants} onClick={() => handleLinkClick()}>
                  All Talent
                </MotionLink>
                {categories.map((category) => {
                  return (
                    <MotionLink to={`/talent/?category=${category.slug}`} key={category.slug} className={item} variants={itemVariants} onClick={() => handleLinkClick()}>
                      {category.categoryFields?.pluralName}
                    </MotionLink>
                  )
                })}
              </div>
            </motion.div>
            <hr className={rule} />
          </>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default CategoryFilter
