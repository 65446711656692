import { useStaticQuery, graphql } from "gatsby"

export const useSiteOptions = () => {
  const {
    wp: {
      siteOptions: { siteOptions },
    },
  } = useStaticQuery(
    graphql`
      query SiteOptions {
        wp {
          siteOptions {
            siteOptions {
              aboutContent
              aboutSubcontent
              contactContent
              newsletterContent
              instagramUrl
              defaultSiteImage {
                sourceUrl
                width
                height
              }
              siteFavicon {
                sourceUrl
              }
            }
          }
        }
      }
    `
  )

  return siteOptions
}