import fetch from "isomorphic-fetch"
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

const cache = new InMemoryCache({
  typePolicies: {
    MediaItem: {
      fields: {
        localFile(_, { readField }) {
          return {
            publicURL: readField("mediaItemUrl"),
          }
        },
      },
    },
  },
})

const client = new ApolloClient({
  link: ApolloLink.from([
    setContext((req, prev) => {
      return {
        headers: {
          ...prev.headers,
          // As long as we are on the same domain with or WP install and Gatsby front end, we can use the x-wp-nonce header to authenticate and fetch previews.
          "X-WP-Nonce": req.variables.nonce ? req.variables.nonce : "",
        },
      }
    }),
    new HttpLink({
      uri: process.env.GATSBY_WPGRAPHQL_URL || "http://localhost:3030/graphql",
      credentials: "include",
    }),
  ]),
  fetch,
  cache,
})

export default client