// extracted by mini-css-extract-plugin
export var burntOrange = "Talent-module--burntOrange--oD1Lh";
export var chalkBoardBlack = "Talent-module--chalkBoardBlack--uiZ2w";
export var cigaretteWhite = "Talent-module--cigaretteWhite--FQc7U";
export var description = "Talent-module--description--RXpe0";
export var descriptionCopy = "Talent-module--descriptionCopy--WuGL5";
export var descriptionInner = "Talent-module--descriptionInner--Omey5";
export var download = "Talent-module--download--Hcpl9";
export var fadedLavender = "Talent-module--fadedLavender--ej4So";
export var link = "Talent-module--link--HS84S";
export var links = "Talent-module--links--nO4ZH";
export var oliveGreen = "Talent-module--oliveGreen--mSojk";
export var overlay = "Talent-module--overlay--P9arJ";
export var overlayInner = "Talent-module--overlayInner--kcu5-";
export var paperWhite = "Talent-module--paperWhite--66+Qe";
export var pistachioGreen = "Talent-module--pistachioGreen--PbEX1";
export var showreelsWrap = "Talent-module--showreelsWrap--Jixgq";
export var title = "Talent-module--title--AXVkB";
export var velvetRed = "Talent-module--velvetRed--IOYoL";