import React from 'react'
import AlmaSymbol from "./icons/AlmaSymbol"
import { footer, symbol } from "../styles/components/Footer.module.css"

const Footer = () => {
  const handleClick = () => {
    if(typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  return (
    <div className={footer}>
      <AlmaSymbol className={symbol} handleClick={handleClick} />
    </div>
  )
}

export default Footer