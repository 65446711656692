export const getPageType = (pathname) => {
  if(pathname === "/talent/" || pathname.startsWith("/talent/?category=")) {
    return "category"
  }else if(pathname.startsWith("/talent/")){
    return "talent"
  } else if(pathname.startsWith("/news")) {
    return "news"
  } else {
    return "home"
  }
}
